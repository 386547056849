import React from 'react'

const TeamCard = ({data}) => {

  return (
    <div className='team-card-item'>
        <figure>
            <img src={data && data?.cover_image} alt="" />
        </figure>
        <p className='text-white text-[12px]'>{data && data?.name}</p>
    </div>
  )
}

export default TeamCard