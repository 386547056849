import React from 'react'
import { InfinitySpin, RotatingLines} from 'react-loader-spinner'
import "./loading.css"
export const InfinityLoading = () => {
  return (
    <div className='loading_spinner'>
      <InfinitySpin
        width='200'
      color="#482FDE"
    />
    </div>
  )
}

export const ButtonLoading = () => {
  <RotatingLines
  strokeColor="#fff"
  strokeWidth="5"
  animationDuration="0.75"
  width="30"
  visible={true}
/>
}
