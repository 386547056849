import React from 'react'
import Icon1 from '../../../assets/img/svg/Event-icon-first.svg'
import Icon2 from '../../../assets/img/svg/Event-icon-second.svg'
import Icon3 from '../../../assets/img/svg/Event-icon-third.svg'
import { useQuery } from '@tanstack/react-query'
import { GetAbout } from '../../../Services/Http'
import '../../../assets/styles/event-card.css'

const AboutEvent = () => {
    const { data: EventAbout } = useQuery({
        queryKey: ['about'],
        queryFn: GetAbout,
        cacheTime: 10 * 60 * 1000,
        staleTime: 5 * 60 * 1000,
        cache: true,
    })
    return (
        <div id='about-event' className="event-container">
            <div className="flex flex-col justify-center items-center text-white text-center">
                <h2 className="font-Inter text-[48px] sm:text-[30px] s:text-[20px]">{EventAbout && EventAbout[0]?.main_head}</h2>
                <p className="text-[#BDBDBD] w-[770px] mt-2 lg:w-full s:text-[12px]">
                {EventAbout && EventAbout[0]?.main_text}
                </p>
            </div>
            <div className="flex justify-center flex-wrap gap-[18px] mt-[92px] sm:mt-[32px]">
                <div className="card-item">
                    <figure className="mt-[14px] s:mt-0 w-[54px] h-[54px]">
                        <img className="w-full h-full" src={Icon1} alt="" />
                    </figure>
                    <h2 className="font-medium text-[20px] s:text-[16px] text-[#F2F2F2] font-Inter mb-[14px]">
                    {EventAbout && EventAbout[0]?.head_1}
                    </h2>
                    <p className="text-[#BDBDBD] s:text-[12px]">
                    {EventAbout && EventAbout[0]?.text_1}
                    </p>
                </div>
                <div className="card-item">
                    <figure className="mt-[14px] s:mt-0 w-[54px] h-[54px]">
                        <img className="w-full h-full" src={Icon2} alt="" />
                    </figure>
                    <h2 className="font-medium text-[20px] s:text-[16px] text-[#F2F2F2] font-Inter mb-[14px]">
                    {EventAbout && EventAbout[0]?.head_2}
                    </h2>
                    <p className="text-[#BDBDBD] s:text-[12px]">
                    {EventAbout && EventAbout[0]?.text_2}
                    </p>
                </div>
                <div className="card-item">
                    <figure className="mt-[14px] s:mt-0 w-[54px] h-[54px]">
                        <img className="w-full h-full" src={Icon3} alt="" />
                    </figure>
                    <h2 className="font-medium text-[20px] s:text-[16px] text-[#F2F2F2] font-Inter mb-[14px]">
                    {EventAbout && EventAbout[0]?.head_3}
                    </h2>
                    <p className="text-[#BDBDBD] s:text-[12px]">
                    {EventAbout && EventAbout[0]?.text_3}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default AboutEvent
