import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
// import Logo from '../../assets/img/svg/kiber_ulduz-logo.svg.svg'
import Logo from '../../assets/img/kiber_ulduz-logo.png'

function Header() {
  const location = useLocation();

  useEffect(() => {
    const hash = location.hash;
    if (hash && hash !== '') {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      window.scrollTo(0, 0);
    }
  }, [location.hash]);

  return (
    <header className='header-container'>
      <div>
      <Link to={"/"}>
      <img className='w-[130px] sm:justify-start sm:w-[78px] sm:h-[33px]' src={Logo} alt="Logo"  />
      </Link>
      </div>
      <ul className="flex items-center gap-[44px] text-[#fff] font-medium">
        <li><Link to="/#about-event">Tədbir haqqında</Link></li>
        <li><Link to="/#faq">Sual Cavab</Link></li>
        <li><Link to="/#team-member">Komandalar</Link></li>
        <li className="ml-[44px] bg-[#005EBE] py-[10px] px-[32px] s:px-[18px] s:text-[10px] rounded-md"><Link to="/leaderboard">Canlı nəticə</Link></li>
      </ul>
  </header>

  )
}

export default Header