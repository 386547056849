import BadgeGold from './../../assets/img/badge-gold.png';
import BadgeSilver from './../../assets/img/badge-silver.png';
import BadgeBronze from './../../assets/img/badge-bronze.png';
import BadgeNormal from './../../assets/img/badge-normal.png';

function LiveBoardCard({ data, index }) {
    const getBadge = (index) => {
        switch (index) {
            case 1:
                return BadgeGold;
            case 2:
                return BadgeSilver;
            case 3:
                return BadgeBronze;
            default:
                return BadgeNormal;
        }
    };

    const getNumSpan = (index) => {
        if (index > 3) {
            return <span className='nums'>{index}</span>;
        } else {
            return null;
        }
    };
    return (
        <div className='team w-full'>
            <div className="team-info">
                <div className="team-badge">
                    <img src={getBadge(index)} alt=''/>
                    {getNumSpan(index)}
                </div>
                <p className='team-info-name'>{data?.team?.name}</p>
            </div>
            <div className="team-time">{data?.last_answered_time}</div>
            <div className="team-score">{data?.point}</div>
        </div>
    )
}

export default LiveBoardCard;
