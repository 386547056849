import React, { useEffect, useState } from 'react'
import Header from './Header'
import { Outlet } from 'react-router'
import Footer from './Footer'
import { InfinityLoading } from '../components/Loading'

const Layout = () => {

  const [loading,setLoading] = useState (false)

  useEffect(() => {
    setTimeout(()=>{
        setLoading(true)
    },1000)
  },[])

  return (
    <>
       {
        loading ? 
        <>
           <Header />
        <main><Outlet /></main>
         <Footer />
        </>
        : <InfinityLoading/> 
       }
    </>
  )
}

// Layout without Footer element(for Leaderboard page)
export const LayoutFooterless = () => {
  const [loading,setLoading] = useState (false)

  useEffect(() => {
    setTimeout(()=>{
        setLoading(true)
    },1000)
  },[])

  return (
    <>
       {
        loading ? 
        <>
          <Header />
          <main><Outlet /></main>
        </>
        : <InfinityLoading/> 
       }
    </>
  )
}

export default Layout