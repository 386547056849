import React, { useEffect } from 'react'
import '../../../assets/styles/table.css'
import BgImg from '../../../assets/img/svg/Müsabiqə cədvəli.svg'
import Aos from 'aos'
import 'aos/dist/aos.css'
import { GetTimeTable } from '../../../Services/Http'
import { useQuery } from "@tanstack/react-query";

function Table() {

    const {data: table} = useQuery({
        queryKey: ["timeTable"],
        queryFn: GetTimeTable,
        cacheTime: 10 * 60 * 1000,
        staleTime: 5 * 60 * 1000,
        cache: true,
    })
    useEffect(() => {
        Aos.init()
    }, [])
    return (
        <div className="relative  pt-[179px] pb-[241px] sm:pb-[80px] sm:pt-[10px]">
            <div className="absolute  w-full h-full top-[13%]">
                <img src={BgImg} alt="" />
            </div>
            <div>
                <h2 className='text-center font-Inter text-white text-[42px] sm:text-[24px] font-normal mb-[85px] sm:mb-[24px]'>Müsabiqə cədvəli</h2>
            </div>
            <div className="px-[9.4vw] table_main">
                <div
                    className="border border-[#0268D0] relative w-full bg-[#111419] text-white z-50 rounded-[4px] pl-[37px] pr-[100px] table_main_box pb-12"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                >
                    <div className="tabel_date">
                        <p
                            className="font-Monument text-[24px] font-extrabold pt-[60px]"
                            data-aos="fade-up"
                            data-aos-duration="1000"
                        >
                            27.12.2023
                        </p>
                    </div>

                    <div className="font-Muller tabel_body">
                        <div
                            className="tabel_body_first"
                            data-aos="fade-up"
                            data-aos-duration="1000"

                        >
                            <div className='tabel_title'>
                                <h5>sa</h5>
                                <h4>Start</h4>
                                <h4>Ending </h4>
                            </div>
                            {
                                table && table.map((data,index) => (
                                <div key={index} className="tabel_body_first__row">
                                <h5 className={`${data?.is_red === true ? "text-[#EB5757]" : "text-[white]"}`}>{data?.name}</h5>
                                <p>{data?.start}</p>
                                <p>{data?.end}</p>
                            </div>
                                ))
                            }
                           
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Table