import { createBrowserRouter } from "react-router-dom";
import Layout, { LayoutFooterless } from "../Layout/Layout";
import ErrorPage from "../Pages/ErrorPage";
import Home from "../Pages/Home";
import LeaderBoard from "../Pages/LeaderBoard";

export const routers = createBrowserRouter([
    {
        element: <Layout />,
        children: [
            {
                path: "/",
                exact: true,
                element:<Home />
            }
        ]
    },
    {
        element: <LayoutFooterless />,
        children: [
            {
                path: '/leaderboard',
                exact: true,
                element: <LeaderBoard />
            }
        ]
    },
    {
        element: <ErrorPage />,
        path: "*"
    }
])