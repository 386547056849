import './../../assets/styles/leaderboard.css';
import { useQuery } from '@tanstack/react-query'
import { GetLiveBoard } from '../../Services/Http';
import LiveBoardCard from '../../components/LiveBoardCard';


function LeaderBoard() {
    const {data: liveBoard} = useQuery({
        queryKey: ['liveBoard'],
        queryFn: GetLiveBoard,
        refetchInterval: 20000, // 30 saniyeden bir sorğu göndərir
        refetchIntervalInBackground: true,
})

    return (
        <div className="leaderboard-wrapper">
            <h1 className="title text-white text-[24px] s:text-[16px] font-bold">Leaderboard</h1>
            <div className="teams">
                <div className='team headers w-full'>
                    <p className='team-info-name'>Komanda</p>
                    <div className="team-time">Time</div>
                    <div className="team-score">Score</div>
                </div>
                {
                    liveBoard && liveBoard.map((data, index) => (
                        <LiveBoardCard key={index} data={data} index={index + 1}/>
                    ))
                }
            </div>
        </div>
    )
}

export default LeaderBoard;
