import React  from "react";
import DownSide from "../../components/Elements/DownSide";
import Carousel from "../../components/Elements/Carousel";
import HomeBanner from "./HomeBanner";
import "../../assets/styles/home.css";
import Table from "./Table";
import Staff from "./Staff";
import AboutEvent from "./AboutEvent";
import FAQ from "./FAQ";
import Teams from "./Teams";

function Home() {

  return (
    <div className="header">
          <div>
            <HomeBanner />
            <DownSide />
            <AboutEvent />
            <Teams />
            {/* <Carousel /> */}
          </div>
          <Table />
          <Staff />
          <FAQ />
        </div>
  );
}

export default Home;
