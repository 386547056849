import React from "react";
import BgImg from '../../../assets/img/bgImg.png'
import "../../../assets/styles/staff.css";
import StaffCard from "../../../components/StaffCard";
import { useQuery } from "@tanstack/react-query";
import { GetPeoples } from "../../../Services/Http";

function Staff() {
  const {data} = useQuery({
    queryKey: ['peoples'],
    queryFn: GetPeoples,
    cacheTime: 10 * 60 * 1000,
    staleTime: 5 * 60 * 1000,
    cache: true,
  })
  const university = data?.filter((data) => data.type_of_person === "university")
  const itManagers = data?.filter((data) => data.type_of_person === "it-managers")
  const rectors = data?.filter((data) => data.type_of_person === "rectors")

  return (
    <div className="p-5 staff relative sm:p-0">
      <div className="absolute bottom-[42px] sm:hidden">
        <img src={BgImg} alt="" />
      </div>
      <div className="mx-auto max-w-screen-xl container gap-4 mb-[160px] sm:mb-0">
        <div>
          <h2 className="font-medium text-[42px] font-Inter text-center text-white mb-[120px] sm:text-[24px] sm:mb-[43px]">Bizim münsiflər heyəti</h2>
        </div>
        <div className="flex flex-wrap justify-center gap-4 relative">
          <StaffCard title="Rectors" data={rectors} />
          <StaffCard title="IT manager" data={itManagers}/>
          <StaffCard title="Institutions" data={university}/>
        </div>
      </div>
    </div>
  );
}

export default Staff;
