import React from 'react'

const ErrorPage = () => {
    return (
        <p className="text-[50px] font-extrabold text-center font-Muller mt-5">
            404 Not Found Page
        </p>
    )
}

export default ErrorPage
