import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import { useQuery } from '@tanstack/react-query';
import { GetPartners } from '../../Services/Http';
import Akta from '../../assets/img/svg/Sponsor-akta.svg'
import Secop from '../../assets/img/svg/Sponsor-secop.svg'
import Socar from '../../assets/img/svg/Sponsor-socar.svg'
import Cybernetics from '../../assets/img/svg/Sponsor-cybernetics.svg'
import 'swiper/css';
import 'swiper/css/pagination';


function DownSide() {
  // const {data} = useQuery({
  //   queryKey: ['partners'],
  //   queryFn: GetPartners
  // })
  return (
<div className='w-full h-[112px] sm:h-full mb-[40px]'> 
<div className='flex justify-around items-center h-full pt[14px]'>
    <Swiper
        breakpoints={{
          0:{
            slidesPerView:4,
            spaceBetween:30,
          },
          480: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 4,
            // spaceBetween: 114,
          },
        }}
        className="mySwiper3"
        autoplay={{
          delay: 1500,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
        >
        {/* {
          data && data.map((data,index)=>(
              <SwiperSlide key={`${data.id}${data.name}${index}`}>
                 <img src={data.logo} alt="" />
              </SwiperSlide>
          ))
        } */}
        <SwiperSlide>
                 <img src={Cybernetics} alt="" />
              </SwiperSlide>
        <SwiperSlide>
                 <img src={Socar} alt="" />
              </SwiperSlide>
        <SwiperSlide>
                 <img src={Secop} alt="" />
              </SwiperSlide>
        <SwiperSlide>
                 <img src={Akta} alt="" />
              </SwiperSlide>
      </Swiper>
</div>
</div>
  )
}

export default DownSide