import React, { useEffect, useState } from 'react'
import Img from '../../../assets/img/svg/HomeBanner-img.svg'
import ResponsiveImg from '../../../assets/img/svg/homeBanner-responsive-img.svg'
import '../../../assets/styles/homebanner.css'
import Clock from '../../../components/Clock';

const HomeBanner = () => {


    const [timerDays, setTimerDays] = useState();
    const [timerHours, setTimerHours] = useState();
    const [timerMinutes, setTimerMinutes] = useState();
    const [timerSeconds, setTimerSeconds] = useState();
    const [countdownFinished, setCountdownFinished] = useState(false);

    let interval

    const startTimer = () => {
        const countDownDate = new Date('April 22, 2024').getTime()

        interval = setInterval(() => {
            const now = new Date().getTime()

            const distance = countDownDate - now

            const days = Math.floor(distance / (24 * 60 * 60 * 1000))
            const hours = Math.floor(
                (distance % (24 * 60 * 60 * 1000)) / (1000 * 60 * 60)
            )
            const minutes = Math.floor(
                (distance % (60 * 60 * 1000)) / (1000 * 60)
            )
            const seconds = Math.floor((distance % (60 * 1000)) / 1000)

            if (distance < 0) {
                clearInterval(interval)
                setCountdownFinished(true)
            } else {
                setTimerDays(days)
                setTimerHours(hours)
                setTimerMinutes(minutes)
                setTimerSeconds(seconds)
            }
        }, 1000)
    }

    useEffect(() => {
        startTimer()
        return () => {
            clearInterval(interval)
        }
    }, [])




    //vaxt tamamlananda happy hacking nece gorseneceyini gostermek uchun --bu qalsin silme IBRAHIM

    // useEffect(() => {
    //     startTimer();
        
    //     const timeout = setTimeout(() => {
    //         setCountdownFinished(true);
    //     }, 5000);
    
    //     return () => {
    //         clearInterval(interval);
    //         clearTimeout(timeout);
    //     };
    // }, []);

    return (
        <div className="container h-[600px] sm:h-full sm:mb-[40px]">
            <div className="flex mx-auto max-w-screen-xl">
                <div className="mt-36 sm:mt-0">
                    <div>
                        <h3 className="font-semibold text-[50px] w-[630px] sm:w-full s:text-[24px] s:w-[266px] s:leading-none leading-[60px] text-white text-4xl sm:text-[24px] sm:mt-[40px] header_title">
                        Kiber Ulduz
                        </h3>
                            <p className='text-white text-[29px] font-semibold'>Milli CTF Liqası</p>
                        <p className="mt-[24px] sm:mt-[17px] mb-[57px] sm:mb-[27px] s:text-[14px] text-[26px] text-white font-Monument sm:text-[14px]">
                            15.03.24 
                        </p>

                        <Clock
                            timerDays={timerDays}
                            timerHours={timerHours}
                            timerMinutes={timerMinutes}
                            timerSeconds={timerSeconds}
                            countdownFinished={countdownFinished}
                        />
                        {countdownFinished && (
                            <div className="happy-hacking-container">
                                <p>Happy Hacking ! &#128540;</p>
                            </div>
                        )}
                    </div>
                </div>
                <div className="lg:block">
                    <div className="flex h-full">
                        <img
                            className="home-banner-bg"
                            src={Img}
                            alt="waves"
                        />
                        <img
                            className="home-banner-responisve-bg"
                            src={ResponsiveImg}
                            alt="waves"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomeBanner
