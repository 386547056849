import React, { useState, useEffect } from 'react'
import '../../assets/styles/footer.css'
import { Link } from 'react-router-dom'
// import Logo from '../../assets/img/svg/kiber_ulduz-logo.svg.svg'
import Logo from '../../assets/img/kiber_ulduz-logo.png'
import Clock from '../../components/Clock'

function Footer() {
   
  const [timerDays, setTimerDays] = useState();
  const [timerHours, setTimerHours] = useState();
  const [timerMinutes, setTimerMinutes] = useState();
  const [timerSeconds, setTimerSeconds] = useState();
  const [countdownFinished, setCountdownFinished] = useState(false);

  let interval

  const startTimer = () => {
      const countDownDate = new Date('April 22, 2024').getTime()

      interval = setInterval(() => {
          const now = new Date().getTime()

          const distance = countDownDate - now

          const days = Math.floor(distance / (24 * 60 * 60 * 1000))
          const hours = Math.floor(
              (distance % (24 * 60 * 60 * 1000)) / (1000 * 60 * 60)
          )
          const minutes = Math.floor(
              (distance % (60 * 60 * 1000)) / (1000 * 60)
          )
          const seconds = Math.floor((distance % (60 * 1000)) / 1000)

          if (distance < 0) {
              clearInterval(interval)
              setCountdownFinished(true)
          } else {
              setTimerDays(days)
              setTimerHours(hours)
              setTimerMinutes(minutes)
              setTimerSeconds(seconds)
          }
      }, 1000)
  }

  useEffect(() => {
      startTimer()
      return () => {
          clearInterval(interval)
      }
  }, [])

    return (
        <footer className="footer">
            <div className="foot-logo">
                <Link to={'/'}>
                    <img
                        src={Logo}
                        alt="Logo"
                        className='w-[130px] sm:w-[105px]'
                    />
                </Link>
            </div>
            <div className="foot-list">
                <ul className="flex justify-around items-center space-x-10 md:items-start">
                    <li className="list-first sm:mt-[42px]">
                        Bizimlə əlaqə
                        <div className="mt-3 text-[14px] font-light">+994 444-44 44</div>
                    </li>

                    <li className="list-first sm:mt-[32px]">
                        Email
                        <div className="mt-3 text-[14px] font-light">
                            happynew.hack@gmail.com
                        </div>
                    </li>
                </ul>
            </div>

            <Clock
                className='mobilClock'
                timerDays={timerDays}
                timerHours={timerHours}
                timerMinutes={timerMinutes}
                timerSeconds={timerSeconds}
                countdownFinished={countdownFinished}
            />
            {countdownFinished && (
                <div className="happy-hacking-container">
                    <p>Happy Hacking ! &#128540;</p>
                </div>
            )}
        </footer>
    )
}

export default Footer
