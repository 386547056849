import React, { Fragment } from 'react'

const Clock = ({ timerDays, timerHours, timerMinutes, timerSeconds, countdownFinished, className}) => {

    const clockStyle = {
        display: countdownFinished ? 'none' : 'block'
    };
    return (
        <Fragment>
            <section className={`timer-container ${className}`}>
                <section className="timer" style={clockStyle}>
                    <div className={`clock ${className}`}>
                        <section>
                            <p className='font-Monument'>{timerDays}</p>
                            <small>Gün</small>
                        </section>
                        <span>:</span>

                        <section>
                            <p className='font-Monument'>{timerHours}</p>
                            <small>Saat</small>
                        </section>{" "}
                        <span>:</span>

                        <section>
                            <p className='font-Monument'>{timerMinutes}</p>
                            <small>Dəqiqə</small>
                        </section>{" "}
                        <span>:</span>

                        <section>
                            <p className='font-Monument'>{timerSeconds}</p>
                            <small>Saniyə</small>
                        </section>
                    </div>
                </section>
            </section>
        </Fragment>
    );
};

Clock.defaultProps={
    timerDays: 0,
    timerHours: 0,
    timerMinutes: 0,
    timerSeconds: 0,
}

export default Clock
