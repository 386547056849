import { useQuery } from '@tanstack/react-query'
import { GetTeams } from '../../../Services/Http'
import React, { useState, useEffect } from 'react'
import TeamCard from '../../../components/TeamCard'
import '../../../assets/styles/team-card.css' 


const Teams = () => {
    const [limit, setLimit] = useState(4) 
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 550) 
    const { data: Teams, refetch } = useQuery({
        queryKey: ['Teams'],
        queryFn: GetTeams,
        initialData: [], 
    })

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 550)
        }
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, []) 

    const handleClick = () => {
        setLimit(prevLimit => prevLimit + 4) 
        refetch() 
    }

    return (
        <div>
            <h2 className="text-center font-Inter text-[42px] font-medium text-white mt-[154px] mb-[52px]">
                Komandalar
            </h2>
            <div className="team-card-container">
                {Teams?.slice(0, isSmallScreen ? limit : Teams?.length).map((data, index) => (
                    <TeamCard key={index} data={data} />
                ))}
            </div>
            <div className='flex justify-center my-[56px]'>
                <button className="load-more-btn" disabled={Teams?.length <= (isSmallScreen ? limit : Teams?.length)} onClick={handleClick}>Hamısına bax</button>
            </div>
        </div>
    )
}

export default Teams
