import axios from 'axios'


const Http = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
        "Content-Type": "application/json"
    }
})

export const GetFAQ = async () => {
    return await Http.get("/faq/").then((res) => res.data)
}
export const GetAbout = async () => {
    return await Http.get("/about/").then((res) => res.data)
}
export const GetPartners = async () => {
    return await Http.get("/partners/").then((res) => res.data)
}
export const GetTeams = async () => {
    return await Http.get("/teams/").then((res) => res.data)
}
export const GetMember = async (id) => {
    return await Http.get("/members/",id).then((res) => res.data)
}
export const GetPeoples = async () => {
    return await Http.get("/peoples/").then((res) => res.data)
}
export const GetTimeTable = async () => {
    return await Http.get("/time/").then((res) => res.data)
}
export const GetLiveBoard = async () => {
    return await Http.get("/leader-board/").then((res) => res.data)
}
