import React, { useState } from 'react'
  import '../../../assets/styles/faq.css'
import { useQuery } from '@tanstack/react-query';
import { GetFAQ } from '../../../Services/Http';
import { AiOutlinePlus, AiOutlineMinus  } from "react-icons/ai";

const FAQ = () => {
  const [showAnswer, setShowAnswer] = useState({});
  const {data:questionsData} = useQuery({
    queryKey: ['question'],
    queryFn: GetFAQ,
    cacheTime: 10 * 60 * 1000,
    staleTime: 5 * 60 * 1000,
    cache: true,
  })
  function handleShowAnswer(id) {
    setShowAnswer(prevState => ({
        ...prevState,
        [id]: !prevState[id] || false,
    }));
}

  return (
    <div id='faq' className='faq-container'>
      <div>
        <h2 className='text-center text-white font-normal text-[48px] font-Inter sm:text-[24px] s:text-[20px] sm:mt-[55px]'>Sual- cavab</h2>
      </div>
      <div className="questions-center">
                {questionsData && questionsData.map((data, index) => (
                    <div key={index} className="questions-center-row">
                        <div onClick={() => handleShowAnswer(data.id)} className="questions-center-row-top">
                        {showAnswer[data.id] ? <AiOutlineMinus /> : <AiOutlinePlus />}<p>{data.question}</p>
                        </div>
                        {showAnswer[data.id] && (
                            <div className="questions-center-row-bottom">
                                <p className='text-[#BDBDBD]'>
                                    {data.answer}
                                </p>
                            </div>
                        )}
                    </div>
                ))}
            </div>
    </div>
  )
}

export default FAQ