import React, { useEffect } from 'react'
import Aos from 'aos'
import "aos/dist/aos.css"
const StaffCard = ({title, data}) => {
  useEffect(()=>{
    Aos.init()
  },[])
  return (
    <div className="staff-group" 
      data-aos="fade-up"
      data-aos-duration="1500"
    >
    <h2 className="staff-title"
      data-aos="fade-up"
      data-aos-duration="1500"
    >
      {title}
    </h2>
    <div className="staff-card">

      <div className="flex flex-wrap justify-start pl-4 gap-4"

        data-aos="fade-up"
        data-aos-duration="1500"
      >
        {data && data.map((item, index) => (
          <div
            key={index}
            className="flex flex-col items-center text-center"
          >
            <img
              src={item.image}
              alt={`Rector ${index + 1}`}
              className="w-20 h-20 sm:w-[76px] sm:h-[76px] rounded-full object-cover border-b border-[3px] border-[#3252b8]"
            />
            <p className="text-[12px] font-normal w-20 mt-[8px] font-Inter">{item.full_name}</p>
          </div>
        ))}
      </div>
    </div>
  </div>
  )
}

export default StaffCard